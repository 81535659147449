import $ from "jquery";

$(".heroSlider").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
	fade: false,
	adaptiveHeight: true,
	infinite: false,
	useTransform: true,
	speed: 400,
	cssEase: "cubic-bezier(0.77, 0, 0.18, 1)"
});

$(".slider-nav")
	.on("init", function(event, slick) {
		$(".slider-nav .slick-slide.slick-current").addClass("is-active");
	})
	.slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		dots: false,
		focusOnSelect: false,
		infinite: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 420,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			}
		]
	});

$(".heroSlider").on("afterChange", function(event, slick, currentSlide) {
	$(".slider-nav").slick("slickGoTo", currentSlide);
	var currrentNavSlideElem =
		'.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
	$(".slider-nav .slick-slide.is-active").removeClass("is-active");
	$(currrentNavSlideElem).addClass("is-active");
});

$(".slider-nav").on("click", ".slick-slide", function(event) {
	event.preventDefault();
	var goToSingleSlide = $(this).data("slick-index");

	$(".heroSlider").slick("slickGoTo", goToSingleSlide);
});

/* ======================== */
/* ======================== */

$(".testimonials-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    dots: false,
    fade: false,
    adaptiveHeight: true,
    infinite: false,
    useTransform: true,
    speed: 400,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)"
});

// Facilities Slider
// =================
$(".facilitiesSlider--slider").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 11000,
	dots: true,
	fade: false,
	adaptiveHeight: true,
	infinite: true,
	useTransform: true,
	speed: 400,
	cssEase: "cubic-bezier(0.77, 0, 0.18, 1)"
});