import $ from "jquery";

$(document).ready(function() {
  // executes when HTML-Document is loaded and DOM is ready

  // breakpoint and up
  $(window).resize(function() {
    if ($(window).width() >= 980) {
      // when you hover a toggle show its dropdown menu
      $(".navbar .dropdown-toggle").hover(function() {
        $(this)
          .parent()
          .toggleClass("show");
        $(this)
          .parent()
          .find(".dropdown-menu")
          .toggleClass("show");
      });

      // hide the menu when the mouse leaves the dropdown
      $(".navbar .dropdown-menu").mouseleave(function() {
        $(this).removeClass("show");
      });

      // do something here
    }
  });


/* ================================================
Bootstrap 4 Hide fixed-top Navbar When Scroll Down
================================================ */
$(function() {
  var lastScrollTop = 0;
  var $navbar = $("header");
  var navbarHeight = $navbar.outerHeight();
  var movement = 0;
  var lastDirection = 0;

  $(window).scroll(function(event) {
    var st = $(this).scrollTop();
    movement += st - lastScrollTop;

    if (st == 0) {
      $navbar.removeClass("scroll-up");
      $navbar.addClass("navbarDark");
    }

    if (st > lastScrollTop) {
      // scroll down
      if (lastDirection != 1) {
        movement = 0;
      }
      var margin = Math.abs(movement);
      if (margin > navbarHeight) {
        margin = navbarHeight;
      }
      margin = -margin;
      $navbar.css("margin-top", margin + "px");

      lastDirection = 1;
      $navbar.removeClass("scroll-up");
      $navbar.removeClass("navbarDark");
    } else {
      // scroll up
      if (lastDirection != -1) {
        movement = 0;
        $navbar.addClass("scroll-up");
      }
      var margin = Math.abs(movement);
      if (margin > navbarHeight) {
        margin = navbarHeight;
      }
      margin = margin - navbarHeight;
      $navbar.css("margin-top", margin + "px");

      lastDirection = -1;
    }

    lastScrollTop = st;
    // console.log(margin);
  });
});

  /* =========== */
  /* =========== */

  //  CONTACT FORM SUBMISSION MESSAGE
  $(".wpcf7").on("wpcf7submit", function() {
    $("#contactFormSection").html(`
    <h2 class="contact__heading">Thank you for your submission!</h2>
    <p class="contact__link contact__link--thin">A member of our team will get back to you as soon as possible.</p>
    `);
  });

  /* =========== */
  /* =========== */
  $('.bookingSelect').on('click', function () {
    $('.selectBox').toggleClass('rotate');
  });

  $('.bookingSelect').focusout(function() {
    $('.selectBox').removeClass('rotate');
  });
  /* ========== */
  /* ========== */
  
  // document ready
});